import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface PublicEnv {
  readonly sentry: {
    readonly release: string;
  };
  readonly kakao: {
    readonly appKey: string;
  };
  readonly iamport: {
    readonly key: string;
  };
  readonly recaptcha: {
    readonly siteKey: string;
  };
}

export const publicEnv: PublicEnv = publicRuntimeConfig;

export const isProd = process.env.NODE_ENV === 'production';
