import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { of } from 'rxjs';
import { polling } from '~/utils';

export interface LoadImageOptions {
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
  canvas?: boolean;
  crop?: boolean;
  orientation?: boolean | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
}

export interface LoadImageResult {
  image: HTMLCanvasElement;
}

export type LoadImage = (
  fileOrBlobOrUrl: File | Blob | string,
  options?: LoadImageOptions,
) => Promise<LoadImageResult>;

declare global {
  interface Window {
    loadImage?: LoadImage;
  }
}

export async function transformImage(file: File, options?: LoadImageOptions) {
  if (typeof window.loadImage !== 'function') {
    throw new Error('"window.loadImage" is undefined.');
  }

  const { image } = await window.loadImage(file, {
    ...options,
    canvas: true,
  });

  if (typeof image.toBlob !== 'function') {
    throw new Error('HTMLCanvasElement#toBlob 지원안됨');
  }

  return new Promise<Blob>((resolve, reject) => {
    image.toBlob((blob) => {
      if (blob == null) {
        reject('이미지를 읽는데 실패함');
      } else {
        resolve(blob);
      }
    });
  });
}

const LOAD_IMAGE_SCRIPT_ID = 'WM_LOAD_IMAGE';
const CANVAS_TO_BLOB_SCRIPT_ID = 'WM_CANVAS_TO_BLOB_SCRIPT_ID';

export function useLoadImage() {
  const [available, setAvailable] = useState(false);
  const [errorCaught, setErrorCaught] = useState(false);

  useEffect(() => {
    const subscription = polling(() => of(window.loadImage != null), {
      count: 5,
    }).subscribe({
      next: (initialized) => {
        if (initialized) {
          subscription.unsubscribe();
          setAvailable(true);
        }
      },
      complete: () => {
        // 4초 내에 결제 모듈을 불러오지 못하면 문제를 야기시킵니다.
        Sentry.captureMessage('5초 내에 loadImage 라이브러리를 불러오지 못함.');
        setErrorCaught(true);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      document.getElementById(LOAD_IMAGE_SCRIPT_ID) != null ||
      document.getElementById(CANVAS_TO_BLOB_SCRIPT_ID) != null
    ) {
      return;
    }

    const script = document.createElement('script');
    script.id = LOAD_IMAGE_SCRIPT_ID;
    script.async = true;
    script.src = 'https://assets.florencecard.me/vendor/load-image.all.min.js';

    const polyfill = document.createElement('script');
    polyfill.id = CANVAS_TO_BLOB_SCRIPT_ID;
    polyfill.src = 'https://assets.florencecard.me/vendor/canvas-to-blob.js';

    document.head.appendChild(polyfill);
    document.body.appendChild(script);

    return () => {
      polyfill.parentNode?.removeChild(polyfill);
      script.parentNode?.removeChild(script);
    };
  }, []);

  return [available, errorCaught] as const;
}
