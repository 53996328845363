import type { Daum } from '@florencecard-typing/daum-postcode';
import { useVendorJS } from './hooks';

export function useDaumPostcode() {
  return useVendorJS<Daum>({
    scripts: [
      {
        id: 'WM_DAUM_SCRIPT',
        src: 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
      },
    ],
    checkIsReady: () => window.daum != null,
    initialize: () => window.daum,
  });
}
