import { copyToClipboard } from '@florencecard-lib/clipboard';
import { ua } from '@florencecard-lib/ua';
import type { KakaoLinkSendDefaultSettings } from '@florencecard-typing/kakao';
import Router from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useKakaoSDK } from '~/core';
import { useAlertDialog } from '~/hooks/alert-dialog';
import { Letter, LetterInfoType, LetterStatus } from '~/models';
import { ApiErrorCodes, queryApiErrorCode } from '~/remotes/api';
import { getLetterByOrderId, getLetterPrice } from '~/remotes/letter';
import { selectLetterInfoValidationMap } from '~/store/selectors';
import { Nullable } from '~/utils';

export function useCreateLetterRouteGuard(target: LetterInfoType) {
  const validation = useSelector(selectLetterInfoValidationMap);
  const guarded = !validation[target];

  useEffect(() => {
    if (guarded) {
      Router.replace('/create');
    }
  }, [target, guarded]);

  return guarded;
}

let cachedLetterPrice: number | undefined;

export function useLetterPrice(revalidate = false) {
  const [price, setPrice] = useState<number | undefined>(cachedLetterPrice);

  useEffect(() => {
    let ignore = false;

    if (cachedLetterPrice == null || revalidate) {
      getLetterPrice().then((price) => {
        if (ignore) {
          return;
        }

        cachedLetterPrice = price;
        setPrice(price);
      });
    }

    return () => {
      ignore = true;
    };
  }, [revalidate]);

  return price;
}

const supportsNavigatorShare = ua.isBrowser ? 'share' in navigator : false;

export function useLetterShareControls() {
  const [kakao, kakaoAvailable] = useKakaoSDK();

  const shareToKakao = useCallback(
    async (letter: Letter) => {
      const { url, shareInfo } = letter;
      const content = {
        title: shareInfo.title,
        imageUrl: shareInfo.imageUrl,
        link: {
          webUrl: url,
          mobileWebUrl: url,
        },
        description: shareInfo.description,
      };

      const settings: KakaoLinkSendDefaultSettings = shareInfo.showPlace
        ? {
            objectType: 'location',
            content,
            address: shareInfo.address,
            addressTitle: shareInfo.addressDetail,
          }
        : {
            objectType: 'feed',
            content,
          };

      kakao?.Link.sendDefault(settings);
    },
    [kakao],
  );

  const copyLetterUrl = useCallback((letter: Letter) => {
    if (copyToClipboard(letter.url)) {
      alert('청첩장 URL이 복사되었습니다.');
    }
  }, []);

  const shareLetterUrl = useCallback(
    async (letter: Letter) => {
      if (!supportsNavigatorShare) {
        copyLetterUrl(letter);
        return;
      }

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (navigator as any).share({
          url: letter.url,
        });
      } catch {
        copyLetterUrl(letter);
      }
    },
    [copyLetterUrl],
  );

  return {
    kakaoAvailable,
    shareToKakao,
    copyLetterUrl,
    shareLetterUrl,
  } as const;
}

export function useInServiceLetterByOrderId(orderId: Nullable<string>) {
  const openAlert = useAlertDialog();
  const { data: letter, error } = useSWR(
    orderId != null ? ['getLetterByOrderId', orderId] : null,
    (_, orderId: string) => getLetterByOrderId(orderId),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (error == null) {
      return;
    }

    switch (queryApiErrorCode(error)) {
      case ApiErrorCodes.청첩장을_찾을_수_없음:
        Router.replace('/404');
        break;
    }
  }, [error]);

  useEffect(() => {
    if (letter != null && letter.status !== LetterStatus.서비스중) {
      openAlert({
        body: '취소 또는 만료된 청첩장 입니다.',
      }).then(() => {
        Router.back();
      });
    }
  }, [letter, openAlert]);

  return letter;
}
