import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { of } from 'rxjs';
import { publicEnv } from '~/env';
import { polling } from '~/utils';

export type IamportPg =
  | 'html5_inicis'
  | 'inicis'
  | 'kcp'
  | 'kcp_billingv'
  | 'uplus'
  | 'nice'
  | 'jtnet'
  | 'kakao'
  | 'kakaopay'
  | 'danal'
  | 'danal_tpay'
  | 'mobilians'
  | 'syrup'
  | 'payco'
  | 'paypal'
  | 'eximbay'
  | 'naverco'
  | 'smilepay'
  | 'alipay';

export type IamportPayMethod =
  | 'card'
  | 'trans'
  | 'vbank'
  | 'phone'
  | 'samsung'
  | 'kpay'
  | 'cultureland'
  | 'smartculture'
  | 'happymoney'
  | 'booknlife';

export interface RequestIamportPayPayload {
  pg?: IamportPg;
  pay_method: IamportPayMethod;
  escrow?: boolean;
  digital?: boolean;
  merchant_uid?: string;
  name?: string;
  amount: number;
  custom_data?: Record<string, unknown>;
  tax_free?: number;
  buyer_name?: string;
  buyer_tel: string;
  buyer_email?: string;
  buyer_addr?: string;
  buyer_postcode?: string;
  m_redirect_url?: string;
  vbank_due?: string;
}

interface RequestIamportPayResponseBase {
  imp_uid: string;
  merchant_uid: string;
}

interface RequestIamportPaySuccessResponse extends RequestIamportPayResponseBase {
  success: true;
}

interface RequestIamportPayFailResponse extends RequestIamportPayResponseBase {
  success: false;
  error_msg: string;
}

export type RequestIamportPayResponse =
  | RequestIamportPaySuccessResponse
  | RequestIamportPayFailResponse;

declare global {
  interface Window {
    IMP?: {
      init(key: string): void;
      request_pay(...args: unknown[]): void;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jQuery?: any;
  }
}

export function requestIamportPay(payload: RequestIamportPayPayload) {
  return new Promise<RequestIamportPayResponse>((resolve) => {
    window.IMP?.request_pay(payload, (response: RequestIamportPayResponse) => {
      resolve(response);
    });
  });
}

const JQUERY_SCRIPT_ID = 'WM_JQUERY_SCRIPT';
const IAMPORT_SCRIPT_ID = 'WM_IAMPORT_SCRIPT';

const checkIamportInitialized = () => window.IMP != null && window.jQuery != null;

export function useIamport() {
  const [available, setAvailable] = useState(false);
  const [errorCaught, setErrorCaught] = useState(false);

  useEffect(() => {
    const subscription = polling(() => of(checkIamportInitialized()), {
      count: 10,
    }).subscribe({
      next: (initialized) => {
        if (initialized) {
          subscription.unsubscribe();
          window.IMP?.init(publicEnv.iamport.key);
          setAvailable(true);
        }
      },
      complete: () => {
        // 10초 내에 결제 모듈을 불러오지 못하면 문제를 야기시킵니다.
        Sentry.captureMessage('10초 내에 IAMPORT 결제 모듈을 불러오지 못함.');
        setErrorCaught(true);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      document.getElementById(JQUERY_SCRIPT_ID) != null ||
      document.getElementById(IAMPORT_SCRIPT_ID) != null
    ) {
      return;
    }

    const jquery = document.createElement('script');
    jquery.id = JQUERY_SCRIPT_ID;
    jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js';

    const script = document.createElement('script');
    script.id = IAMPORT_SCRIPT_ID;
    script.async = true;
    script.src = 'https://cdn.iamport.kr/js/iamport.payment-1.1.5.js';

    document.head.appendChild(jquery);
    document.head.appendChild(script);

    return () => {
      jquery.parentNode?.removeChild(jquery);
      script.parentNode?.removeChild(script);
    };
  }, []);

  return [available, errorCaught] as const;
}
