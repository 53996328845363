import type {
  KakaomapMap,
  KakaomapMapConstructOptions,
  KakaomapMarker,
} from '@florencecard-typing/kakaomap';
import { useEffect, useRef } from 'react';

export function queryAddress(query: string) {
  return new Promise<{ x: number; y: number } | null>((resolve, reject) => {
    const kakaomap = window.kakao?.maps;

    if (kakaomap == null) {
      reject(new Error('카카오맵 SDK가 초기화되지 않았습니다. 잠시 후에 다시 시도해주세요.'));
      return;
    }

    const geocoder = new kakaomap.services.Geocoder();

    geocoder.addressSearch(query, (result, status) => {
      switch (status) {
        case 'OK':
          resolve({
            x: result[0].x,
            y: result[0].y,
          });
          break;
        case 'ZERO_RESULT':
          resolve(null);
          break;
        case 'ERROR':
          reject(new Error('카카오맵 장애로 오류가 발생하였습니다. 잠시 후에 다시 시도해주세요.'));
          break;
      }
    });
  });
}

export function coord2Address(x: number, y: number) {
  return new Promise<{ address: string } | null>((resolve, reject) => {
    const kakaomap = window.kakao?.maps;

    if (kakaomap == null) {
      reject(new Error('카카오맵 SDK가 초기화되지 않았습니다. 잠시 후에 다시 시도해주세요.'));
      return;
    }

    const geocoder = new kakaomap.services.Geocoder();

    geocoder.coord2Address(x, y, (result, status) => {
      switch (status) {
        case 'OK':
          resolve({
            address: result[0].road_address?.address_name ?? result[0].address.address_name,
          });
          break;
        case 'ZERO_RESULT':
          resolve(null);
          break;
        case 'ERROR':
          reject(new Error('카카오맵 장애로 오류가 발생하였습니다. 잠시 후에 다시 시도해주세요.'));
          break;
      }
    });
  });
}

interface UseKakaomapOptions extends Omit<KakaomapMapConstructOptions, 'center'> {
  x: number;
  y: number;
}

export function useKakaomap<T extends HTMLElement = HTMLElement>(options?: UseKakaomapOptions) {
  const mapRef = useRef<KakaomapMap | null>(null);
  const markerRef = useRef<KakaomapMarker | null>(null);
  const mapContainerRef = useRef<T>(null);

  useEffect(() => {
    const kakaomap = window.kakao?.maps;
    const mapContainer = mapContainerRef.current;

    // 이미 지도가 생성되었으면 스킵
    if (mapRef.current != null) {
      return;
    }

    if (kakaomap == null || mapContainer == null || options == null) {
      return;
    }

    const { x, y, ...otherOptions } = options;
    const center = new kakaomap.LatLng(y, x);

    const map = new kakaomap.Map(mapContainer, {
      center,
      ...otherOptions,
    });

    markerRef.current = new kakaomap.Marker({
      map,
      position: center,
    });

    mapRef.current = map;
  }, [options]);

  useEffect(() => {
    const kakaomap = window.kakao?.maps;
    const map = mapRef.current;
    const marker = markerRef.current;

    if (map != null && options != null && kakaomap != null && marker != null) {
      const { x, y } = options;
      const center = new kakaomap.LatLng(y, x);

      map.setCenter(center);
      marker.setPosition(center);
    }
  }, [options]);

  return [mapContainerRef, mapRef] as const;
}
