import type { NextComponentType, NextPageContext } from 'next';
import type { FunctionComponent, ReactNode } from 'react';

type Primitive = string | boolean | number | undefined | null;

export type NextPageMetadataData<Result extends Primitive, P = Record<string, unknown>> =
  | Result
  | ((data: { pathname: string; pageProps: P }) => Result);

export interface NextPageMetadata<P = Record<string, unknown>> {
  title?: NextPageMetadataData<string, P>;
  description?: NextPageMetadataData<string, P>;
  author?: NextPageMetadataData<string, P>;
  keywords?: NextPageMetadataData<string, P>;
  showHeader?: NextPageMetadataData<boolean, P>;
  ogImage?: NextPageMetadataData<string, P>;
  ogImageType?: NextPageMetadataData<string, P>;
}

type PropsWithChildren = Record<string, unknown> & {
  children: ReactNode;
};

export type NextPage<P = Record<string, unknown>> = NextComponentType<
  NextPageContext,
  unknown,
  P
> & {
  metadata?: NextPageMetadata<P>;
  Wrapper?: FunctionComponent<PropsWithChildren>;
};

export function isNextPage<P>(Component: unknown): Component is NextPage<P> {
  return Component != null && typeof Component === 'function';
}
