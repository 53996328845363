import { ua } from '@florencecard-lib/ua';
import { useCallback, useEffect, useState } from 'react';
import { createLocalStorage } from '~/utils/storage';

const storage = createLocalStorage();

interface ValueWithExpiry<T> {
  expiresAt: number;
  value: T;
}

function parseValueWithExpiry<T>(key: string, fallback: T | null) {
  const data = storage.get(key);

  if (data == null) {
    return fallback;
  }

  try {
    const { expiresAt, value } = JSON.parse(data) as ValueWithExpiry<T>;
    const now = new Date().getTime();

    if (now > expiresAt) {
      storage.delete(key);
      return fallback;
    }

    return value;
  } catch {
    return fallback;
  }
}

export function usePersistentFlagWithExpiry(key: string, defaultMarked = true) {
  const [marked, setMarked] = useState(
    ua.isBrowser ? parseValueWithExpiry(key, defaultMarked) === true : null,
  );

  useEffect(() => {
    setMarked(parseValueWithExpiry(key, defaultMarked) === true);
    // NOTE: 한번만 실행
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unmarkWithExpiry = useCallback(
    (expiresIn: number) => {
      const now = new Date().getTime();
      const value: ValueWithExpiry<boolean> = {
        expiresAt: now + expiresIn,
        value: false,
      };

      storage.set(key, JSON.stringify(value));
      setMarked(false);
    },
    [key],
  );

  return [marked, unmarkWithExpiry] as const;
}
