import {
  Button,
  CommonModalProps,
  Modal,
  ModalHead,
  ModalTitle,
  ModalCloseButton,
  Spacing,
  Stack,
  Txt,
} from '@florencecard/components';
import { colors } from '@florencecard-shared/colors';
import React, { ComponentProps, ReactNode } from 'react';

interface Props extends CommonModalProps<boolean> {
  title?: ReactNode;
  body?: ReactNode;
  showCloseButton?: boolean;
  confirmButton?: ReactNode | string;
  cancelButton?: ReactNode | string;
  dialogProps?: Omit<ComponentProps<typeof Modal>, 'open' | 'onClose' | 'children'>;
}

export default function ConfirmDialog({
  open,
  onClose,
  title,
  body,
  showCloseButton = true,
  confirmButton = '확인',
  cancelButton = '취소',
  dialogProps,
}: Props) {
  const titleEl = typeof title === 'string' ? <ModalTitle>{title}</ModalTitle> : title;
  const bodyEl =
    typeof body === 'string' ? (
      <Txt
        as="p"
        fontSize={13}
        lineHeight="1.5"
        color={colors.grayBold}
        padding="0 16px"
        maxWidth="100%"
        wordBreak="break-all"
        overflowWrap="break-word"
        css={{
          textSizeAdjust: 'none',
        }}
      >
        {body}
      </Txt>
    ) : (
      body
    );
  const confirmButtonEl =
    typeof confirmButton === 'string' ? (
      <Button size={44} fontSize={14} color={colors.primary} onClick={() => onClose?.(true)}>
        {confirmButton}
      </Button>
    ) : (
      confirmButton
    );

  const cancelButtonEl =
    typeof cancelButton === 'string' ? (
      <Button size={44} fontSize={14} color={colors.grayBold} onClick={() => onClose?.(false)}>
        {cancelButton}
      </Button>
    ) : (
      cancelButton
    );

  return (
    <Modal open={open} onClose={onClose} width="80vw" maxWidth={280} {...dialogProps}>
      <div>
        {title != null || showCloseButton ? (
          <ModalHead>
            {title != null ? titleEl : null}
            {showCloseButton ? <ModalCloseButton /> : null}
          </ModalHead>
        ) : null}
        {body != null ? bodyEl : null}
        <Spacing size={24} />
        <Stack.Horizontal
          alignItems="center"
          justifyContent="flex-end"
          gutter={8}
          padding="0 8px 4px 8px"
        >
          {cancelButtonEl}
          {confirmButtonEl}
        </Stack.Horizontal>
      </div>
    </Modal>
  );
}
