import type { Kakao } from '@florencecard-typing/kakao';
import { publicEnv } from '~/env';
import { useVendorJS } from './hooks';

export function useKakaoSDK() {
  return useVendorJS<Kakao>({
    scripts: [
      {
        id: 'WM_KAKAO_SDK_SCRIPT',
        src: 'https://assets.florencecard.me/vendor/kakao.min.js',
      },
    ],
    checkIsReady: () => window.Kakao != null,
    initialize: () => {
      if (!window.Kakao?.isInitialized()) {
        window.Kakao?.init(publicEnv.kakao.appKey);
      }
      return window.Kakao ?? null;
    },
  });
}
