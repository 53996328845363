import { CommonModalProps, useModal } from '@florencecard/components';
import React, { ComponentProps, useCallback } from 'react';
import ConfirmDialog from '~/components/ConfirmDialog';

type Props = Omit<ComponentProps<typeof ConfirmDialog>, keyof CommonModalProps<void>>;

export function useConfirmDialog() {
  const modal = useModal();

  return useCallback(
    (props: Props) => {
      return modal.open<boolean>((close) => <ConfirmDialog onClose={close} {...props} />);
    },
    [modal],
  );
}
